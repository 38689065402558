@import '../../../../styles/media-mixins';

.permissionsInputLabel {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.34;
    letter-spacing: 0.32px;
    display: inline-block;
    margin-bottom: 0.5rem;
    color: #525252;
    font-weight: 400;
    line-height: 1rem;
    vertical-align: baseline;
}

.permissionsRequiredInputLabel {
    &::after {
        color: var(--neutral1000);
        content: ' *';
    }
}
.permissionsPanel {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 8px 12px;
    border-radius: 10px;
    background-color: #fde5e5;
    color: #d0363c;

    &__header {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        border-bottom: none !important;
        & > img {
            width: 18px;
            margin-top: 3px !important;
            margin-right: 8px;
        }
        &__title {
            color: #d0363c;
            font-weight: bold;
            font-size: 15px;
            width: calc(100% - 26px);
        }
        &__content {
            color: #d0363c;
            font-size: 15px;
            margin-left: 26px;
            width: calc(100% - 26px);
        }
    }

    &__content {
        margin-left: 30px;
        & > p {
            margin-top: 10px;
            line-height: 16.42px;
            color: #d0363c;
        }
    }
}
