.screenLoader {
    width: calc(100vw + 250px);
    position: relative;
    z-index: 9999999;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #16161636;
    height: 100%;
    display: flex;
    position: absolute;
    top: 0px;
    bottom: 0px;

    & > span {
        display: block;
        width: 90px;
        height: 90px;
        border: 10px solid transparent;
        border-radius: 50%;
        border-top-color: var(--primary700);
        border-right-color: var(--primary700);
        border-bottom-color: var(--primary700);
        animation: spinner 0.7s linear infinite;
        position: fixed;
        left: auto;
        right: auto;
        top: 50%;
        left: calc(50% - 54px);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}

@keyframes spinner {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}