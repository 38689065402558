.actions {
  display: flex;
  justify-content: flex-end;
}

.action__iconContainer {
  height: 16px;
  width: 16px;
  padding: 2px;
  margin-left: 8px;
  background-color: transparent;
  &:last-child {
    margin-top: 0;
  }
  .action__icon {
    height: 10px;
    width: 10px;
  }
}
