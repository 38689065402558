@import "../../../../styles/media-mixins";

.formContainer {
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin: 20px auto;

    @include min-width($mobileMD) {
        max-width: 570px !important;
        padding: 30px 25px 0;
        text-align: right;
    }
    @include min-width($tabletSM) {
        max-width: none;
        padding: 30px 30px 0px;
    }

    & > div > .topLink {
        padding: 10px 0;
        margin: 0 20px;

        &:hover {
            text-decoration: underline;
        }
    }

    &__panel {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        height: fit-content;
        margin: 0 auto;
        padding: 15px;
        width: calc(100% - 30px);
        border-radius: 20px;
        text-align: left;

        @include min-width($mobileMD) {
            max-width: 550px !important;
            padding: 25px;
            text-align: right;
            box-shadow: 0 5px 13px var(--boxShadowLigh);
            background-color: white;
        }
        @include min-width($tabletSM) {
            padding: 25px 30px;
        }

        & > form {
            width: 100%;

            .legend {
                margin: 5px 0;
                font-size: 14px;
                text-align: left;
            }

            & > div {
                margin-top: 15px;

                & > label {
                    font-family: var(--base);
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    text-decoration: none;
                    color: '#393939';
                }

                & > .link {
                    padding: 0 0 10px;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .ctaBar {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 100%;
                padding: 5px 0 0;

                @include min-width($mobileMD) {
                    align-content: center;
                    align-items: center;
                    margin: 15px 0 0;
                    margin-left: auto;
                }

                .ctaButton {
                    gap: 24px;
                    min-height: none !important;
                    display: flex;
                    align-content: center;
                    align-items: center;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                    width: calc(50% - 12px);
                    height: 36px !important;
                    border: none;

                    & > img {
                        display: none;
                        width: 15px;
                        height: 15px;
                        margin-left: auto;
                        color: white;

                        @include min-width($mobileSM) {
                            display: inline-block;
                        }
                    }

                    &:hover {
                        box-shadow: 0 5px 13px var(--boxShadowLigh);
                    }
                }

                .ctaSeparator {
                    text-align: center;
                    width: 100%;
                    height: 30px;
                    margin-bottom: 20px;
                    border-bottom: 1px solid #6c6c6c;
                    position: relative;
                    &__text {
                        position: absolute;
                        bottom: -6px;
                        background-color: white;
                        padding: 0 15px;
                        transform: translateX(-50%);
                        color: #6c6c6c;
                    }
                }

                .ctaLink {
                    min-height: none !important;
                    display: flex;
                    align-content: center;
                    align-items: center;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                    width: calc(50% - 12px);
                    height: 36px !important;
                    background-color: white;
                    border: none;
                    border: 1px solid var(--neutral500);

                    &:hover {
                        box-shadow: 0 5px 13px var(--boxShadowLigh);
                    }

                    & > img {
                        display: none;

                        @include min-width($mobileSM) {
                            display: inline-block;
                            margin-right: 10px;
                        }
                    }
                }

                .disabled {
                    &:hover {
                        border: none;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}
