@import '../../../../styles/media-mixins';

.screenHeader {
    &__topBar {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        &__title {
            display: flex;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin-right: 10px;

            &__text {
                font-size: 20px;
                letter-spacing: 0.3px;
                font-weight: bold;
                color: var(--neutral1000);
                line-height: 22px;
            }
        }
        & > .modal__header__primaryButton {
            margin-top: 5px;
            height: 48px;
            margin-left: auto;
        }
    }

    &__description {
        margin-top: 20px;

        @include min-width($mobileMD) {
            margin-top: 30px;
        }
    }
}

.nestedRoutesBackButton {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    &__parent {
        display: flex;
        align-items: center;
        width: fit-content;
        height: 20px;
        margin-right: 8px;
        &:hover {
            color: var(--primary700);
            text-decoration: underline;
        }
        & > svg {
            margin-right: 4px;
        }
    }
    &__child {
        margin-left: 8px;
    }
}
