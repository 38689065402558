.credStatus {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    & > div {
      margin: 0 !important;
      padding: 4px 8px;
      border: none;
      border-radius: 4px;
    }
    & > span {
      margin-left: 4px;
    }

    & > button {
      margin-left: -20px;
      width: 16px;
      background-color: transparent;

      & > img {
        width: 10px;
      }
    }

    .selectable {
      padding: 5px 24px 5px 8px;
      cursor: pointer;
      &:hover {
        box-shadow: 0 3px 10px var(--boxShadowDark);
      }
    }
}
