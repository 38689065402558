@import '../../../../../styles/media-mixins';
@import '../../../../../styles/colors';

tr:nth-last-child(-n + 3) > td > div > div > div:nth-last-child(1) {
    &::after {
        margin-top: -60px;
    }
}

tr:nth-last-child(-n + 2) > td > div > div > div:last-child > ul {
    margin-top: -120px;
    margin-left: 73px;
}

tr:nth-last-child(-n + 4) > td > div > div > div:last-child > ul {
    margin-top: -120px;
    margin-right: 28px;
}

.selectTag {
    position: relative;
    @include flexbox();
    @include align-items(center);
    & > div {
        position: relative;
        width: 181px;
        width: 32px;
        top: 0;
        top: 0;
    }

    & > .typeTag {
        width: fit-content !important;
        z-index: 99999;
        margin-right: 10px;

        & > div {
            &:after {
                content: 'Remove';
                display: none;
                position: absolute;
                z-index: 9999;
                width: 60px;
                height: 36px;
                color: var(--neutral100);
                font-size: 10px;
                line-height: 36px;
                text-align: center;
                background: rgba(0, 0, 0, 0.72);
                border-radius: 3px;
            }

            &:hover {
                &:after {
                    display: block;
                }
            }
            & > img {
                cursor: pointer;
            }
        }
    }
}

.actions {
  @include flexbox();
  @include justify-content(flex-end);
}

.noBorder {
    border-bottom: none !important;
}

// General table styles
.panelContainer {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  padding-bottom: 0 !important;

  @include min-width($mobileXL) {
    padding: 20px;
    padding-bottom: 0 !important;
    border-radius: 20px;
    background-color: var(--neutral100);
    box-shadow: 0 2px 4px var(--boxShadowLigh);
  }
}

.table {
  overflow-x: scroll;
  overflow-y:initial;
  padding: 5px 5px 0px;
  border-radius: 10px;
  box-shadow: none;
  width: 100%;
  padding-bottom: 0 !important;
  &::-webkit-scrollbar-thumb {
    border: 2px solid var(--neutral100); /* should match background, can't be transparent */
    border-radius: 8px;
    background-color: var(--neutral300);
  } 

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  @include min-width($mobileXL) {
    padding: 0;
  }

  & > table {
    border-collapse: collapse !important;
    width: 100%;
    padding-bottom: 46px;

    & > thead {
      display: none;
      height: 2rem;

      @include min-width($mobileXL) {
        display: table-header-group;
      }

      & > tr > th {
        padding: 10px;
        border-bottom: 1px solid var(--skeletonLightBG);
        color: var(--neutral700);
        cursor: default;

        @include min-width($desktopSM) {
          font-size: 14px;
        }

        & > div {
          @include flexbox();
          @include align-items(flex-start);
          & > img {
            height: 100%;
          }
          & > span:not(:last-child) {
            margin-left: 0px;
          }
        }

        & > span > svg {
          opacity: 1;
        }
      }
    }

    & > tbody {
      & > tr {
        height: 3rem;
        min-height: 50px;
        border-bottom: 1px solid var(--skeletonLightBG);

        & > td {
          font-size: 14px;
          max-width: 400px;
          min-height: 50px;
          padding: 10px;
          border-bottom: 1px solid var(--skeletonLightBG);
          color: var(--neutral1000);

          @include min-width($desktopSM) {
            max-width: 420px;
          }
          overflow-wrap: break-word;
          text-overflow: ellipsis;
          word-wrap: break-word;

          @media (min-width: 775px) and (max-width: 925px) {
            max-width: 250px;
          }

          & > .checkbox {
            justify-content:flex-start;
            padding: 0;
            margin: 0;
            border-bottom: none !important;
            cursor: pointer;
            & > input {
              margin: 0;
            }
          }

          & > .detailLink {
            padding: 15px;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }

          & > .modal__content > div {
            cursor: pointer;
            @include flexbox();
            @include align-items(center);
            & > .modalAction {
              width: 15px;
              height: 15px;
              padding: 2px;
              border-radius: 10px;
              margin-left: 5px;
              &:hover {
                padding: 1px;
                border: 1px solid var(--neutral1000);
              }
              &__icon {
                width: 10px;
              }
            }
          }
          & > .action__content {
            cursor: pointer;
            @include flexbox();
            @include align-items(center);
          }

          & > .status__content {
            @include flexbox();
            @include align-items(center);

            & > div {
              margin: 0 !important;
              padding: 5px 5px;
              border: none;
              border-radius: 10px;
            }
            & > span {
              margin-left: 10px;
            }
          }
        }
      }

      & > .table__mobileDisplay {
        height: auto;
      }
    }
  }

  &__mobileDisplay {
    display: block;

    @include min-width($mobileXL) {
      display: none;
    }
  }

  &__mobileDisplay:not(:last-child) {
    margin-bottom: 10px;
  }

  &__longDisplay {
    display: none;

    @include min-width($mobileXL) {
      display: table-row;
    }
  }
}

.disabledPagination {
  pointer-events: none;
  opacity: 0.2;
  & > div {
    cursor: not-allowed !important;
  }
}

.hoveredRow {
  background-color: var(--primary100);
  cursor: pointer;
  & > td > a {
    color: var(--primary700);

  }
}
