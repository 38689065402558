@import "../../../../styles/colors";
@import "../../../../styles/media-mixins";

.license {
  display: block;
  padding: 20px;
  border-radius: 12px;
  position: relative;
  box-shadow:  0px 0px 8px 0px var(--boxShadowExtraLigh);
  background-color: var(--neutral100);
  display: flex;
  flex-direction: column;

  &__tag {
    display: block;
    position: absolute;
    width: 100px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border-radius: 3px;
    top: -12px;
    border: 1px solid var(--neutral300);
    border-radius: 10px;
    height: auto;
    left: 75px;
    background-color: var(--neutral100);
  }

  &__header {
    width: 100%;
    @include flexbox();
    @include flex-direction(row);
    @include flex-wrap(wrap);
    @include justify-content(space-between);

    &__title {
      width: calc(100% - 75px);
      margin-bottom: 0;
    }

    &__popularTag {
      width: fit-content;
      margin-left: auto;
      max-height: 22px;
    }
  }

  &__price {
    margin-top: auto;
  }

  &__customPrice {
    margin-top: 24px;
  }

  &__features {
    margin-top: 16px;
    padding-top: 24px;
    margin-bottom: 24px;
    border-top: 1px solid var(--neutral300);
    width: 100%;
    gap: 16px;
    @include flexbox();
    @include justify-content(space-between);
    @include flex-wrap(wrap);
    & > div {
      @include min-width(646px) {
        min-width: 249px;
      }
    }

    &__list__item {
        margin-top: 16px;
        align-items: center;
        @include flexbox();
        @include flex-direction(row);
        &__content {
          @include flexbox();
          @include flex-direction(row);
          @include flex-wrap(wrap);
          margin-left: 8px;
          & > .featureName {
            width: fit-content;
            margin-bottom: 0;
          }
        }
      }
    
  }

  &__button {
    width: 100%;
  }
}

.toogle {
  cursor: auto !important;
  color: var(--neutral600);
  &:hover {
    color: var(--neutral600);
  }
  & > svg {
    margin-top: 2px;
    margin-left: 4px;
  }
}
