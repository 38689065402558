@import "../../../../styles/media-mixins";

.backgroundLayer {
    position: fixed;
    z-index: 999999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: auto;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    max-width: 100vw;
    width: 100vw;
    height: 100vh;
    @media (min-width: 775px) {
        left: 250px;
        max-width: calc(100vw - 250px);
    }

    & > div {
        width: 100%;
        height: 100%;
        display: block;
        max-width: 1184px;
        position: relative;
    }
}

.modal {
    position: fixed;
    z-index: 10;
    top: 50%;
    left: 0;
    max-width: 1080px;
    padding: 40px;
    border-radius: 20px;
    background: white;
    transform: translate(calc(44vw - 150px), -50%);
    width: calc(340px - 80px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 5px 13px var(--boxShadowLigh);
    @media (min-width: 775px) {
        left: 0px;
        transform: translate(50%, -50%);
    }
    @media (min-width: $desktopMD) {
        left: 200px;
        transform: translate(50%, -50%);
    }
    @include min-width($tabletSM) {
        width: 400px;
    }

    &__close {
        margin-bottom: 12px;
        & > svg {
            cursor: pointer;
            margin-left: auto;
            &:hover {
                & > path {
                    stroke: red !important;
                }
            }
        }
    }
    
    & > img {
        width: 120px;
        margin-bottom: 40px;
    }

    &__title {
        text-align: center;
        margin-bottom: 8px;
    }

    &__description {
        text-align: center;
        margin-bottom: 52px;
    }

    & > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 12px;
        & > button {
            display: flex;
            justify-content: center;
            width: calc(50% - 6px);
            cursor: pointer;
        }
    }
}
