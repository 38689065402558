@import "../../../../styles/media-mixins";

.inputSkeleton {
    &__label {
        display: inline-block;
        margin-bottom: 0.5rem;
        color: #525252;
        font-size: 0.75rem;
        font-weight: 400;
        letter-spacing: 0.32px;
        line-height: 1.34;
        vertical-align: baseline;
    }

    &__required {
        &::after {
            color: var(--neutral1000);
            content: " *";
        }
    }

    &__span {
        position: relative;
        display: block;
        width: 100%;
        height: 20px;
        height: 2.5rem;
        padding: 0 1rem;
        border: none;
        background: var(--skeletonLightBG);
        pointer-events: none;
    }
}

@keyframes skeleton {
    0% {
        opacity: 0.3;
        transform: scaleX(0);
        transform-origin: left;
    }

    20% {
        opacity: 1;
        transform: scaleX(1);
        transform-origin: left;
    }

    28% {
        transform: scaleX(1);
        transform-origin: right;
    }

    51% {
        transform: scaleX(0);
        transform-origin: right;
    }

    58% {
        transform: scaleX(0);
        transform-origin: right;
    }

    82% {
        transform: scaleX(1);
        transform-origin: right;
    }

    83% {
        transform: scaleX(1);
        transform-origin: left;
    }

    96% {
        transform: scaleX(0);
        transform-origin: left;
    }

    100% {
        opacity: 0.3;
        transform: scaleX(0);
        transform-origin: left;
    }
}


@mixin skeleton {
    position: relative;
    padding: 0;
    border: none;
    background: var(--skeletonLightBG);
    box-shadow: none;

    pointer-events: none;

    &:hover, &:focus, &:active {
        border: none;
        outline: none;
        cursor: default;
    }

    &::before {
        position: absolute;
        width: 100%;
        height: 100%;
        background: var(--skeletonDarkBG);
        content: "";
                animation: 3000ms ease-in-out skeleton infinite;

        -webkit-animation: skeleton 3s ease-in-out infinite;
        will-change: transform-origin, transform, opacity;
    }
}

@keyframes skeleton {
    0% {
        opacity: 0.3;
        transform: scaleX(0);
        transform-origin: left;
    }

    20% {
        opacity: 1;
        transform: scaleX(1);
        transform-origin: left;
    }

    28% {
        transform: scaleX(1);
        transform-origin: right;
    }

    51% {
        transform: scaleX(0);
        transform-origin: right;
    }

    58% {
        transform: scaleX(0);
        transform-origin: right;
    }

    82% {
        transform: scaleX(1);
        transform-origin: right;
    }

    83% {
        transform: scaleX(1);
        transform-origin: left;
    }

    96% {
        transform: scaleX(0);
        transform-origin: left;
    }

    100% {
        opacity: 0.3;
        transform: scaleX(0);
        transform-origin: left;
    }
}

@-webkit-keyframes skeleton {
    0% {
        opacity: 0.3;
        transform: scaleX(0);
        transform-origin: left;
    }

    20% {
        opacity: 1;
        transform: scaleX(1);
        transform-origin: left;
    }

    28% {
        transform: scaleX(1);
        transform-origin: right;
    }

    51% {
        transform: scaleX(0);
        transform-origin: right;
    }

    58% {
        transform: scaleX(0);
        transform-origin: right;
    }

    82% {
        transform: scaleX(1);
        transform-origin: right;
    }

    83% {
        transform: scaleX(1);
        transform-origin: left;
    }

    96% {
        transform: scaleX(0);
        transform-origin: left;
    }

    100% {
        opacity: 0.3;
        transform: scaleX(0);
        transform-origin: left;
    }
}

.skeleton {
    @include skeleton;
}
