#root {
    width: 100vw;
    overflow-y:
    scroll;
    overflow-x: hidden;
    position:absolute;
    top: 0;
    bottom: 0;
    white-space: pre-line;
}

:host {
    #root {
    width: 100vw;
    overflow-y:
    scroll;
    overflow-x: hidden;
    position:absolute
    }
}
html {
    height: 100%;
}

body {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    min-height: 100%;
    margin: 0;
    background-color: #F2F2F2;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a, span {
    text-decoration: none;
}

h1, h2, h3, h4, h5, p, a, ul, span, li {
    color: var(--neutral1000);
}
ul {
    display: block;
    list-style-type: none;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
}

body > iframe {
  display: none;
}

p, h2 {
    margin: 0
}

div {
    display: inline;
}