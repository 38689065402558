@import "../../../../styles/media-mixins";

.topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px !important;
  padding: 0 10px 0 0 !important;
  border-bottom: none !important;
  background-color: var(--neutral100) !important;
  z-index: 800 !important;

  @media (min-width: 775px) {
    width: calc(100% - 30px);
    height: 70px !important;
    padding: 0 15px 0 15px !important;
  }

  .logoCont {
    display: flex;
    align-items: center;
    height: 35px;

    & > img {
      height: 100%;
    }
  }

  .user__image {
    width: 35px;
    height: 35px;
    margin-top: 2px;
    border-radius: 100%;
    background-color: #ffffff;
  }

  .helpImage {
    width: 40px;
    height: 40px;
  }

  .rightSpace {
    display: none;
    align-items: center;
    justify-content: center;
    min-width: fit-content;
    margin-left: auto;

    @media (min-width: 775px) {
      display: flex;
    }

    &__support {
      margin-right: 10px;
    }

    .rightMenuIcon {
      width: 15px;
      margin-top: 10px;
    }
  }
}

.panelContainer {
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: content-box;
  padding: 75px 20px 20px 20px;
  background-color: var(--neutral200);
  padding-bottom: 98px;
  display: flex;
  flex-direction: column;

  @include min-width($mobileMD) {
    padding: 75px 30px 32px;
    padding-bottom: 98px;
  }

  @media (min-width: 775px) {
    display: flex;
    flex-direction: column;
    width: calc(100vw - 310px);
    margin-top: 30px;
    margin-left: 250px;
    padding-top: 75px;
  }

  & > div {
    width: 100%;
    max-width: 1184px;
    height: fit-content;

    & > section {
      margin-bottom: 20px;
    }
  }

  & > .extraPanel {
    border-radius: 8px;
    margin: 10px 0 32px !important;
  }
}

.topLink {
  margin: 20px 20px 0;
  margin-top: auto;
  padding: 20px 20px 5px;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--neutral200);
  z-index: 99999;

  & > span {
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.panelContainerTwo {
  padding: 20px;
  margin-top: 126px;
  @include min-width($mobileMD) {
    padding-left: 0;
    padding: 30px;
  }
  @include min-width(775px) {
    padding: 32px;
    padding-left: 0;
    width: calc(100vw - 282px);
  }
}

.goBack {
  position: fixed;
  display: block;
  padding: 20px;
  margin-top: 50px;
  width: calc(100% - 55px);
  background-color: var(--neutral200);
  z-index: 99999;

  @include min-width($mobileMD) {
  padding: 30px 30px 10px;
  width: calc(100% - 75px);
  }

  @media (min-width: 775px) {
  margin-top: 70px;
  width: fit-content;
  padding: 32px 32px 0px;
  }

  & > span {
    cursor: pointer;
    gap: 4px;
    @include flexbox();
    @include align-items(center);
    &:hover{
      color: var(--primary700);
      text-decoration: underline;
      & > svg > path {
        stroke: var(--primary700);
      }
    }
  }
}