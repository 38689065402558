@import "../../../../../../styles/media-mixins.scss";

.extProvideActionsCell {
  width: 100px;
  & > p {
    width: fit-content;
    margin-left: calc(100% - 52px);
  }
}

.extProvideActions {
    position: relative;
    @include flexbox();
    @include align-items(center);

    & > button {
      min-width: 100px;
    }
}
