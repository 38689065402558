@import '../../../../styles/media-mixins';

.actionPanel {
    padding: 20px;
    width: calc(100% - 40px);
    background-color: #fde5e5;
    border: 1px solid var(--alert300);
    border-radius: 12px;
    gap: 20px;
    @include flexbox();
    @include align-items(center);
    @include flex-direction(row);
    @include flex-wrap(wrap);

    &__header {
        min-width: 320px;
        @include flexbox();
        @include flex-direction(column);

        @media (min-width: 1230px) {
            width: calc(100% - 160px);
            min-width: 350px;
        }

        & > .title {
            margin-bottom: 5px;
        }

        & > img {
            width: 20px;
            margin-right: 10px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        @media (min-width: 1230px) {
            flex-direction: row;
            align-items: center;
            align-items: center;
            justify-content: space-between;
        }

        &__texts {
            & > p {
                line-height: 1.5;
                margin-right: auto;
            }
        }

        &__buttons {
            display: flex;
            flex-direction: row;
            margin-left: auto;
            & > .button {
                margin-left: auto;
                border: 2px solid #dc3d43;
                min-width: 140px;
                display: flex;
                justify-content: center;
                text-align: center;
                width: min-content;
                @include min-width($desktopMD) {
                    margin-top: 0;
                }
            }
        }

        &__close {
            margin-left: 16px;
            cursor: pointer;
            & > svg {
                width: 16px;
                height: 16px;
            }
             &:hover {
                & > svg > path {
                    fill: red;
                }
            }
        }

        & > .fullWidth {
            width: 100% !important;
        }
    }
}

.yellow {
    background-color: var(--warning100) !important;
    border: 1px solid var(--warning200) !important;
    & > div > button {
        background-color: var(--warning300) !important;
        border: none !important;
        &:hover {
            background-color: var(--warning400) !important;
        }
        & > span {
            color: var(--neutral1000) !important;
        }
    }
}
