@import '../../../../styles/media-mixins';

.subTiers {
    padding: 24px;
    gap: 24px;
    @include flexbox();
    @include flex-direction(column);

    & > ul {
        width: 100%;
        gap: 24px;
        @include flexbox();
        @include flex-wrap(wrap);
        @include flex-direction(row);

        & > li {
            width: 100%;
            & > div {
                height: calc(100% - 40px);
                width: calc(100% - 40px);
            }
        }
    }

    &__header {
        width: 100%;
        display: flex;
        gap: 10px;
        @include justify-content(space-between);
        @include align-items(flex-end);
        @include flex-wrap(wrap-reverse);
        @include flex-direction(row);

        & > div {
            @include flexbox();
            @include flex-direction(column);
            gap: 4px;
        }

        & > button {
            margin-left: auto;
        }
    }

    &__closeButton {
        margin-left: auto;
        cursor: pointer;
    }
}

.infoPanel {
    width: calc(100% - 24px);
}

.oneTierContainer > .tier > div > div:nth-child(3) > div {
     @include min-width($desktopSM) {
        @include flex(0 0 calc(50% - 12px));
    }
}

.twoTiersContainer > .tier {
    @include min-width($desktopSM) {
        @include flex(0 0 calc(50% - 12px));
    }
}


.threeTiersContainer > .tier {
    @include min-width($desktopSM) {
        @include flex(0 0 calc(50% - 12px));
    }
    @include min-width(1270px) {
        @include flex(0 0 calc((100% / 3) - 16px));
    }
}

.fourTiersContainer  > .tier {
    @include min-width($desktopSM) {
        @include flex(0 0 calc(25% - 18px));
    }
}

.tierSkeleton {
    min-height: 500px;
}
