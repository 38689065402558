@import '../../../../../../styles/media-mixins';

.panels {
    @include flexbox();
    @include flex-direction(column);
    @include flex-wrap(wrap);
    gap: 32px;
    @include min-width($desktopXL) {
        min-width: 350px;
        @include flex-wrap(0 1 432px);
        @include flex-wrap(nowrap);
    }
}

.planPanel {
    width: calc(100% - 48px);
    gap: 20px;
    padding: 24px;
    border-radius: 12px;
    @include flexbox();
    @include flex-wrap(wrap);
    @include flex-direction(column);
    @include justify-content(flex-start);

    &__header {
        width: 100%;
        gap: 20px;
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);
    }
}

.dataPanelsContainer {
    width: 100%;
    gap: 24px;
    @include flexbox();
    @include flex-wrap(wrap);
}

.dataPanel {
    border: 1px solid #C6C6C8;
    width: 100%;
    padding: 24px;
    border-radius: 12px;
    gap: 8px;
    flex: auto;
    @include flexbox();
    @include flex-wrap(wrap);
    @include flex-direction(column);

    @include min-width($desktopSM) {
        width: 214px;
    }
    @include min-width(1198px) {
        min-width: 316px;
    }
    @include min-width(1493px) {
        min-width: 214px;
        width: 214px;
    }
}

.buttonContainer {
    gap: 12px;
    justify-content: flex-start;
    width: 100%;
    @include flexbox();
    @include flex-wrap(wrap);

    & > button {
        width: 100%;
        @include min-width($tabletXL) {
            width: fit-content;
        }
    }
}

.warningPanelContainer {
    display: block;
    width: calc(100% - 40px) !important;
    margin-left: 0px !important; 
}