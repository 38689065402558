@import "../../../../../styles/media-mixins";
@import "../../../../../styles/fonts";

.gaTextInputContainer.required {
    label {
        &::after {
            color: var(--neutral700);
            content: ' *';
        }
    }
}

.gaTextInputContainer {
    position: relative;
    label {
        text-align: left;
        width: 100%;
        font-family: var(--base);
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-decoration: none;
        color: var(--neutral700);
        display: inline-block;
        margin-bottom: 0.5rem;
        vertical-align: baseline;
    }

    input {
        letter-spacing: 0.16px;
        outline: 2px solid transparent;
        outline-offset: -2px;
        width: calc(100% - 2rem);
        height: 2.5rem;
        padding: 0 1rem;
        border: none;
        border-radius: 8px;
        color: var(--neutral1000);
        background-color: transparent;
        border: 1px solid var(--neutral400);
        font-family: var(--base);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-decoration: none;
    }

    & > button {
        margin-left: auto;
        margin-top: 0.7rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border: 1px solid var(--neutral1000);
        color: var(--neutral1000);
        padding: 5px 8px;
        border-radius: 8px;
        cursor: pointer;

        &:hover {
            background-color:rgba(255, 255, 255, 0.2);
        }
        &:focus {
            box-shadow: inset 0px 0px 0px 2px var(--primary300);
        }
        & > span {
            margin-right: 7px;
        }
    }
}

.gaTextInputContainer.readOnly {
   input {
        background-color: var(--neutral200);
        border: 1px solid var(--neutral200);
        color: var(--neutral600);
    }
}

.maxLengthLabel {
    margin-left: auto;
    position: absolute;
    top: 5px;
    right: 0;
}