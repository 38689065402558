@import "../../../../../styles/media-mixins";

.backgroundLayer {
    position: fixed;
    z-index: 999999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    max-width: 100vw;
    width: 100vw;
    height: 100vh;
    cursor: auto;
    @media (min-width: 775px) {
        left: 250px;
        width: calc(100vw - 250px);
        max-width: 1250px;
    }
}

.modal {
    position: fixed;
    z-index: 10;
    top: 50%;
    max-width: 1080px;
    padding: 32px;
    border-radius: 20px;
    background: var(--neutral100);
    width: calc(350px - 64px);
    gap: 32px;
    box-shadow: 0 5px 13px var(--boxShadowLigh);
    max-height: calc(90vh - 64px);
    max-width: calc(90vh - 64px);
    left: calc(50%);
    transform: translate(-50%, -50%);
    @include flexbox();
    @include justify-content(center);
    @include flex-direction(column);

    @media (min-width: 775px) {
        left: calc(50%);
        transform: translate(-50%, -50%);
    }
    @include min-width($tabletSM) {
           width: 596px;
    }

    @include min-width($desktopSM) {
        left: 0;
        transform: translate(calc(44vw - 150px), -50%);
    }
        

    &__header {
        & > div {
            gap: 32px;
            @include flexbox();
            @include flex-wrap(wrap);
            @include flex-direction(row-reverse);

            & > .close {
                margin-left: auto;
                color: var(--neutral1000);
                & > svg {
                    cursor: pointer;
                    margin-left: auto;
                    &:hover {
                        & > path {
                            stroke: var(--alert300) !important;
                        }
                    }
                }
            }
        }
    }

    &__content {
        gap: 12px;
        overflow-x: scroll;
        padding: 0 10px 0 0;
        @include flexbox();
        @include flex-direction(column);
    }

    &__title {
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        margin-bottom: 10px;
    }

    &__description {
        text-align: center;
        margin-bottom: 32px;
        font-size: 14px;
    }

    & > .buttons {
        gap: 24px;
        @include flexbox();
        @include flex-wrap(wrap);
        & > button {
            width: 100%;
            @include min-width($tabletSM) {
                width: calc(50% - 12px);
            }
        }
    }
}

.doubleColumn {
    gap: 12px;
    @include flexbox();
    @include flex-wrap(wrap);
    @include align-items(flex-end);
    @include justify-content(space-between);

    & > div {
        flex: 1;
        min-width: 212px;
    }

    & > div:nth-child(3) {
        margin-top: 15px;
    }

    & > div:nth-child(4) {
        margin-top: 15px;
    }
}
