@import "../../../../styles/media-mixins";

.sideNav {
    width: 250px;
    height: calc(100vh - 48px);
    background-color: #ffffff;
    margin-top: 48px;
    position: absolute;
    top: 0;
    left: 0;
    @media (min-width: 775px) {
        height: calc(100vh - 70px);
        margin-top: 70px;
        background-color: var(--neutral100);
        transform: translateX(0);
        transition: transform 0.3s linear;
    }

    & > .sideNav__list {
        display: flex;
        flex-direction: column;

        padding-top: 0;
        list-style: none;

        @media (min-width: 775px) {
            margin-top: 70px;
            position: relative;
            margin-top: 0;
            height: 100%;
            flex-direction: column;
            padding-top: 10px;
        }

        & > li {
            @media (min-width: 775px) {
                cursor: pointer;
            }

            & > img {
                width: 20px;
                height: 20px;
            }
            & > span {
                margin-left: 10px;
                color: #181b5e !important;
                font-weight: 500;
                letter-spacing: 0.32px !important;
                line-height: 16px !important;
                height: 20px;
                padding: 4px 0;
                font-size: 14px;
            }

            & > div {
                padding: 5px 7px 5px 7px;
                margin: 0 15px 5px 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row;
                border-radius: 4px;
                min-height: 35px;
                & > div {
                    display: flex;
                    align-items: center;
                    & > .icon {
                        width: 20px;
                        height: 20px;
                    }
                    span {
                        margin-left: 10px;
                        color: var(--neutral1000);
                        font-weight: 500;
                        letter-spacing: 0.32px !important;
                        line-height: 16px !important;
                        min-height: 20px;
                        padding: 4px 0;
                        font-size: 14px;
                    }
                }
            }

            & > .sideNav__list {
                display: flex;
                flex-direction: column;
                & > li {
                    display: flex;
                    align-items: center;
                    padding: 5px 8px 5px 8px;
                    margin: 0 15px 5px 45px;

                    height: 35px;
                    & > span {
                        margin-left: 0;
                        font-size: 14px;
                    }
                }
            }
        }

        @media (max-width: 768px) {
            position: relative;
            top: 0;
            left: 0;
            flex-flow: column nowrap;
            width: 250px;
        }
    }

    .user {
        display: flex;
        align-items: center;
        padding: 1rem;
        border-top: 1px solid var(--neutral1000);
        border-bottom: 1px solid var(--neutral1000);
        color: var(--neutral1000);
        font-size: 0.8rem;
        transform: translateX(0);
        transition: transform 0.3s linear;

        @media (min-width: 775px) {
            display: none;
        }

        & > div > p {
            font-size: 0.8rem;
        }
    }

    .userImage {
        width: 30px;
        margin-right: 1rem;
        border-radius: 100px;
    }

    .extraMobileOptions {
        @media (min-width: 775px) {
            display: none;
        }

        & > li {
            &:last-child {
                border-top: 2px solid #181b5e6b;
            }

            & > a > span {
                color: var(--neutral1000) !important;
                font-weight: 500;
                letter-spacing: 0.32px !important;
                line-height: 16px !important;
                font-size: 14px;
            }
        }
    }
}

.navOpen {
    @media (max-width: 775px) {
        transform: translateX(0);
        transition: transform 0.3s linear;
    }
}

.navClosed {
    @media (max-width: 775px) {
        transform: translateX(-250%);
        transition: transform 0.3s linear;
        width: 240px;
    }
}

.sideNav__link {
    list-style-type: none;
    @media (min-width: 775px) {
        cursor: pointer;
    }

    & > img {
        width: 20px;
        height: 20px;
    }
    & > span {
        margin-left: 10px;
        color: #181b5e !important;
        font-weight: 500;
        letter-spacing: 0.32px !important;
        line-height: 16px !important;
        height: 20px;
        padding: 4px 0;
        font-size: 14px;
    }

    & > div {
        &:hover {
            background-color: #f6f6f6;
        }
    }
    & > .sideNav__list > li {
        &:hover {
            background-color: #f6f6f6;
        }
    }
}

.sideNav__link__disabled {
    & > div > div {
        width: 100%;
        & > img:last-child {
            margin-left: 10px;
            margin-top: 2px;
        }
        & > span {
            font-size: 14px;
        }
    }
}

.sideNav__link.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
        background-color: #f6f6f6;
    }
}
.sideNav__link.row:hover {
    background-color: #f6f6f6;
}
.column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.selected {
    background-color: var(--neutral200) !important;
    & > div > span {
        color: var(--primary700) !important;
    }
}

.optionsSkeleton {
    width: 219px;
    margin: 4px 15px;
    height: 35px;
    border-radius: 3px !important;
}

.sandboxPanel {
    margin-top: auto;
    display: none;
    width: 215px;
    padding: 15px;
    margin: auto auto 15px;
    background-color: var(--primary700);
    border: none;
    border-radius: 10px;
    & > button {
        color: var(--primary700);
    }
    @media (min-width: 775px) {
        display: block;
    }

    & > p {
        color: white;
    }

    &__title {
        font-size: 15px;
        font-weight: bold;
    }

    &__description {
        font-size: 13px;
        line-height: 18px;
        margin-top: 5px;
        text-shadow: 1px 1px var(--boxShadowLigh);
    }

    &__button {
        width: 100%;
        height: 30px;
        margin-top: 12px;
        border: none;
        color: #ff7e79;
        font-weight: bold;
        display: flex;
        justify-content: center;
    }
}

.sandboxPanelSmart {
    margin-top: auto;
    display: block;
    width: 215px;
    padding: 10px;
    margin: auto auto 15px;
    background-color: #ff7e79;
    border: none;
    border-radius: 10px;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 15px;

    @media (min-width: 775px) {
        display: none;
    }

    & > p {
        text-align: center;
        font-size: 15px;
        font-weight: bold;
        color: white;
    }
}
