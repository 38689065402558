@import "../../../../../../../../styles/colors.scss";
@import "../../../../../../../../styles/media-mixins.scss";

.totalContainer {
    @include flexbox();
    @include flex-wrap(nowrap);
    @include align-items(center);

    .totalFeatures {
        margin-left: 12px;
        line-height: 16px;
        color: var(--neutral1000);

        &>span {
            font-family: var(--base);
            font-weight: 700;
            line-height: 16px;
        }
    }
}