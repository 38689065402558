@import '../../../../../styles/media-mixins';
@import '../../../../../styles/fonts';

.gaDatePickerContainer.required {
    & > div {
        display: flex;
        flex-direction: column;

        & > label {
            font-family: var(--base);
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            text-decoration: none;
            color: var(--neutral700) !important;
            margin-bottom: 0.5rem;

            &::after {
                color: var(--neutral700);
                content: ' *';
            }
        }

        & > input {
            margin-right: 0 !important;
            letter-spacing: 0.16px;
            outline: 2px solid transparent;
            outline-offset: -2px;
            width: calc(100% - 2rem);
            height: 2.5rem;
            padding: 0 1rem;
            border-radius: 8px;
            background-color: transparent;
            border: 1px solid var(--neutral400);
            color: #161616;
            font-family: var(--base);
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-decoration: none;
        }
    }
}

.gaDatePickerContainer {
    height: fit-content;
    & > div  {
        display: flex;
        flex-direction: column;

        & > label {
            font-family: var(--base);
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            text-decoration: none;
            margin-bottom: 0.5rem;
            color: var(--neutral1000) !important;
        }

        & > input {
            margin-right: 0 !important;
            letter-spacing: 0.16px;
            outline: 2px solid transparent;
            outline-offset: -2px;
            width: calc(100% - 2rem);
            height: 2.5rem;
            padding: 0 1rem;
            border: none;
            border-radius: 8px;
            background-color: transparent;
            border: 1px solid var(--neutral400);
            color: var(--neutral1000);
            font-family: var(--base);
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-decoration: none;
        }
    }
}

.gaDatePickerContainer.readOnly  > div  > input {
    margin-right: 0 !important;
    letter-spacing: 0.16px;
    outline: 2px solid transparent;
    outline-offset: -2px;
    width: calc(100% - 2rem);
    height: 2.5rem;
    padding: 0 1rem;
    border: none;
    border-radius: 8px;
    background-color: var(--neutral200);
    border: 1px solid var(--neutral200);
    color: var(--neutral600);
    font-family: var(--base);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-decoration: none;
}
