// Colors 
.neutral600 {
    color: var(--neutral600)
}

.neutral700 {
    color: var(--neutral700)
}

.neutral800 {
    color: var(--neutral800)
}

.neutral1000 {
    color: var(--neutral1000)
}

.neutral100 {
    color: var(--neutral100)
}

.primary700 {
    color: var(--primary700)
}

.alert300 {
    color: var(--alert300)
}
.success300 {
    color: var(--success300)
}
.purpleBackground {
    background-color: var(--primary700);
}
.greenBackground {
    background-color: var(--secondary600);
}
.redBackground {
    background-color: var(--tertiary600);
}
.purpleSemiBackground {
    background-color: var(--primary300);
    svg path {
        stroke: var(--primary700);
    }
    span {
        color: var(--primary700);
    }
}
.greenSemiBackground {
    background-color: var(--secondary200);
    svg path {
        stroke: var(--secondary600);
    }
    span {
        color: var(--secondary600);
    }
}
.redSemiBackground {
    background-color: var(--tertiary200);
    svg path {
        stroke: var(--tertiary600);
    }
    span {
        color: var(--tertiary600);
    }
}
