@import '../../../../styles/media-mixins';

.infoPanel {
    padding: 12px;
    border-radius: 8px;
    background-color: var(--info100);
    color: var(--neutral1000);
    border: 1px solid var(--info200);
    gap: 12px;
    @include flexbox();
    @include align-items(flex-start);
    @include flex-wrap(wrap);
    & > p {
        max-width: calc(100% - 36px);
    }
}
