@import "../../../../../styles/colors.scss";
@import "../../../../../styles/media-mixins.scss";

.each_data__stats {
    @include flexbox;
    @include flex-direction(column);
    @include flex-wrap(wrap);
    @include align-items(flex-end);
    gap: 8px;
    .stats__percentage {
        text-align: center;
        border-radius: 4px;
        padding: 4px 8px;
        @include flexbox;
        @include flex-wrap(nowrap);
        @include align-items(center);
        @include justify-content(center);

        svg {
            max-height: 16px;
            max-width: 16px;
        }

        span {
            margin-left: 4px;
        }
    }
    .stats__label {
        color: var(--neutral100);
        text-align: right;
    }
}