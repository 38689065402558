@import '../../../../styles/media-mixins';

.sections {
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0 10px;

    @media (min-width: 700px) {
        flex-direction: row;
        overflow-y: hidden;
    }
}

.qrModule {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    padding: 0 20px 20px 20px;
    text-align: left;

    @media (min-width: 640px) {
        padding: 0 30px 0 30px;
    }
    @media (min-width: 775px) {
        padding: 0 30px 0 30px;
    }
    @media (min-width: 700px) {
        width: 51%;
        height: 100%;
    }

    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 11px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 10px;
    }

    &::-webkit-scrollbar-thumb {
        border: 2px solid white;
        /* should match background, can't be transparent */
        border-radius: 8px;
        background-color: var(--neutral300);
    }

    @include min-width($mobileMD) {
        flex-direction: row;
        max-width: 800px !important;
    }

    & > section {
        max-width: 340px;
    }

    & > div:first-child {
        @include min-width($tabletSM) {
            width: fit-content;
        }
    }

    h4 {
        @include min-width($tabletSM) {
            padding-top: 10px;
        }
    }

    .infoContainer {
        max-width: 476px;

        & > img {
            width: 85px;
            display: none;

            @media (min-width: 1026px) {
                display: block;
            }
        }

        & > p:nth-child(2) {
            margin-top: 20px;
            color: var(--neutral1000);

            @media (min-width: 1026px) {
                margin-top: 40px;
            }

            & > span {
                color: var(--primary700) !important;
            }
        }

        & > p:not(:nth-of-type(1)) {
            & > span {
                padding-left: 12px;
            }
        }

        @include min-width($tabletSM) {
            max-width: 476px;
        }

        .marketsContainer {
            display: flex;
            flex-direction: column;

            @include min-width($tabletSM) {
                margin: 10px 0;
            }

            div {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }

            a > img {
                height: 40px;
                margin: 10px 20px 0 0;

                @include min-width($mobileMD) {
                    margin: 10px 20px 0 0;
                }

                @include min-width($tabletSM) {
                    height: 45px;
                }
            }
        }
    }

    .qrContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: fit-content;
        height: fit-content;
        margin: 0 0 20px 0;
        padding: 15px;
        border-radius: 20px;
        background-color: white;
        box-shadow: 0 5px 13px var(--boxShadowLigh);
        text-align: left;

        @include min-width($mobileMD) {
            width: 220px;
            margin: 0 0 0 40px;
        }

        @include min-width($tabletSM) {
            min-width: 280px;
            padding: 15px 20px;
        }

        p {
            padding: 0;

            img {
                width: 13px;
                margin-left: 5px;
            }

            &:nth-child(3) {
                text-align: right;
            }
        }
    }

    .emailLoginLink {
        margin: 0 0 20px;
        margin-right: auto;
        padding: 10px 10px 10px 0;

        @include min-width($mobileMD) {
            display: none;
        }

        &:hover {
            font-weight: bold;
        }
    }

    .marketButton {
        & > svg {
            width: 98px;
        }

        &:hover {
            & > svg {
                box-shadow: 0 5px 13px #00000011;
            }
        }
    }

    .scanStep {
        display: flex;
        flex-direction: row;
        align-items: center;
        & > svg {
            margin-left: 10px;
            & > rect {
                fill: var(--primary700);
            }
        }
    }
}

.qrSection {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;

    @media (min-width: 700px) {
        height: 100%;
        width: calc(49% - 40px);
    }
}
