@import '../../../../../../../../styles/media-mixins';
@import '../../../../../../../../styles/colors';
@import '../../../../../../../../styles/fonts';

.skeleton {
    height: calc(500px - 48px);
    margin: 0;
    @include min-width($desktopXXL) {
        height: 412px;
    }
}

.filters__container {
    .filtersLeftContent {
        margin-bottom: 24px;
        display: block;
    }
}
.metricsContainer {
    background-color: var(--neutral100);
    border-radius: 12px;
    padding: 24px;
    display: block;
    .featuresDataContainer {
        @include flexbox;
        @include flex-wrap(nowrap);
        @include align-items(center);
        @include justify-content(space-between);
    }

    .chartContainer {
        display: block;
        margin-top: 24px;

        & > div {
            display: block;
            max-height: 232px;
        }

        a {
            display: none;
        }
    }
}
