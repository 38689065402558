@import "../../../../styles/colors.scss";
@import "../../../../styles/media-mixins.scss";

.purpleButton {
    border-radius: 8px;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    cursor: pointer;

    & > svg {
        margin-left: 8px;
    }
    & > span {color:  var(--neutral100);}
}

// SIZES
.large {
    padding: 12px 24px;
    height: 48px;
}

.medium {
    padding: 12px 24px;
    height: 48px;
}

.small {
    padding: 8px 16px;
    height: 32px;
}

// STYLE
.filled {
    background-color: var(--primary700);
    color: var(--neutral100);
    border: 1px solid var(--primary700);
    &:hover {
        background-color: var(--primary900);
    }
    &:focus {
        box-shadow: inset 0px 0px 0px 2px var(--primary300);
    }
}

.outlined {
    background-color: var(--neutral100);
    border: 1px solid var(--primary700);
    & > span {
        color: var(--neutral1000);
    }
    &:hover {
        border: 1px solid var(--primary700);
        & > span {
            color: var(--primary700);
        }
    }
}

.filledDisabled {
    border: 1px solid var(--neutral500);
    background-color: var(--neutral500);
    color: var(--neutral100);
    cursor: auto !important;
    & > span {
        color: var(--neutral100);
    }
    &:hover {
        border: 1px solid var(--neutral500);
        background-color: var(--neutral500);
        color: var(--neutral100);
        & > span {
            color: var(--neutral100);
        }
    }
}

.outlinedDisabled {
    border: 1px solid var(--neutral500);
    background-color: var(--neutral100);
    color: var(--neutral500);
    cursor: auto !important;
    & > span {
        color: var(--neutral1000);
    }
    &:hover {
        border: 1px solid var(--neutral500);
        background-color: var(--neutral100);
        color: var(--neutral500);
        & > span {
            color: var(--neutral1000);
        }
    }
}