@import "../../../styles/media-mixins";

.searchBar {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-radius: 8px;
    margin: 10px 0 15px;
    box-shadow: 0 3px 10px var(--boxShadowLigh);

    &__icon {
        position: absolute;
        z-index: 2;
        display: block;
        // width: 38px;
        // height: 37px;
        padding: 0 10px;
    }

    &__input {
        width: 100%;
        padding: 8px 12px;
        padding-left: 40px;
        border-radius: 8px;
        border: none;
        border-right: 1px solid #e3e3ec70;
        border-bottom: 1px solid var(--neutral300);
    }
}
