@import "../../../../styles/colors.scss";
@import "../../../../styles/media-mixins.scss";

.skeleton {
    margin: 0;
    @include min-width($desktopXXL) {
        max-width: calc(245px - 40px);

    }
}
.homeBlocks__container {
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(space-between);
    width: 100%;
    gap: 20px;
    .homeBlocks__each {
        @include flex( 0 0 calc(100% - 40px));
        border-radius: 12px;
        padding: 20px;
        height: calc(150.95px - 40px);
        @include min-width($tabletXL) {
            @include flex(0 0 calc((100%/2) - (40px + 20px)));
        }
        @include min-width($desktopXL) {
            @include flex(0 0 calc((100%/3) - (40px + 20px)));
        }
        @include min-width($desktopXXL) {
            @include flex(0 0 calc(100% - 40px));
        }
        .homeBlocks__each_container {
            @include flexbox();
            @include flex-direction(column);
            @include justify-content(space-between);
            height: 100%;
            .homeBlocks__each_title {
                color: var(--neutral100);
                margin-bottom: 24px;
            }
            .homeBlocks__each_data {
                @include flexbox;
                @include flex-wrap(nowrap);
                @include justify-content(space-between);
                .each_data__number {
                    color: var(--neutral100);
                }
            }
        }
    }
}

// Tooltip
[aria-hidden="true"][aria-label][tooltip-position]::before,
[aria-hidden="true"][aria-label][tooltip-position]::after {
    display: none;
}

[aria-label][tooltip-position]::before {
    font-size: 12px;
}