@import '../../../../styles/media-mixins';

.viewContainer {
    @include flexbox();
    gap: 32px;
}

.tierDetail {
    gap: 32px;
    border-radius: 12px;
    width: 100%;
    @include flexbox();
    @include flex-direction(column);

    @include min-width($desktopMD) {
        width: calc(100% - 369px);
    }

    label {
        color: var(--neutral1000) !important;
        font-family: var(--base) !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 20px !important;
        text-decoration: none !important;
    }

    & > .infoPanel {
        background-color: var(--warning100);
        border: 1px solid var(--warning200);
    }

    & > div > form {
        gap: 24px;
        width: 100%;
        @include flexbox();
        @include flex-wrap(wrap);
        @include flex-direction(column);

        & > .doublePriceColumn {
            position: relative;
            width: 100%;
            gap: 12px;
            @include flexbox();
            @include justify-content(space-between);
            @include flex-wrap(wrap);
            @include flex-direction(column);
            & > div {
                min-width: 100%;
                flex: 1;
                position: relative;
                @include min-width($mobileMD) {
                    min-width: 267px;
                }
                & > .pricePlaceholder {
                position: absolute;
                left: 13px;
                top: 35px;
                @include min-width($mobileMD) {
                    min-width: 240px;
                    }
                }
                // div {
                //     display: block;
                // }
                & > div > div > div  {
                    width: 100%;
                    display: block;
                }
                // input {
                //     // width: calc(100% - 24px);
                //     // padding: 0px 12px 20px;
                //     // height: calc(48px - 18px);
                //     // z-index: 99;
                //     // position: relative;
                // }
            }

            & > .pricePlaceholder:nth-child(4) {
                right: 13px;
            }
        }
        & > .section {
            gap: 16px;
            @include flexbox();
            @include flex-wrap(wrap);
            @include flex-direction(column);
        
        & > .doubleColumn {
            position: relative;
            width: 100%;
            gap: 12px;
             @include flexbox();
            @include justify-content(space-between);
            @include flex-wrap(wrap);
            & > div {
                min-width: 100%;
                flex: 1;
                position: relative;
                @include min-width($mobileMD) {
                    min-width: 267px;
                }
                div {
                    display: block;
                }
                & > div > div > div  {
                    width: 100%;
                    display: block;
                }
            }
        }
    }
}

    & > div:nth-child(3) {
        gap: 32px;
    }

    & > div:nth-child(4) {
        gap: 32px;
    }

    &__header {
        width: 100%;
        display: flex;
        gap: 10px;
        @include justify-content(space-between);
        @include align-items(flex-end);
        @include flex-wrap(wrap);

        & > div {
            @include flexbox();
            @include flex-direction(column);
            gap: 4px;
        }
    }
}

.tierPreviewContainer {
    display: none;
    min-width: 337px ;
    @include min-width($desktopMD) {
        display: block;
    }
}
.tierPreview {
    width: calc(337px - 52px);
    border-radius: 12px;
    gap: 32px;
    padding: 20px 20px 20px 32px;
    @include flexbox();
    @include flex-direction(column);

    & > button {
        width: 100%;
        height: 44px;
    }
}

.tagToogle {
    cursor: auto !important;
    color: var(--neutral600);
    &:hover {
      color: var(--neutral600);
    }
}


[aria-label][tooltip-position] {
  position: relative;
}

[aria-label][tooltip-position]::before,
[aria-label][tooltip-position]::after {
  --scale: 0;
  position: absolute;
  transition: transform 100ms;
  transition-timing-function: linear;
}

[aria-label][tooltip-position]:hover::before,
[aria-label][tooltip-position]:hover::after {
  --scale: 1;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.45, 1.93);
}

[aria-label][tooltip-position]::before {
  content: attr(aria-label);
  background-color: var(--neutral1000);
  padding: 8px;
  border-radius: 4px;
  text-align: center;
  transform: scale(0);
}

[aria-label][tooltip-position]::after {
  content: "";
  border: var(--arrow-size) solid transparent;
}

[aria-label][tooltip-position]::before,
[aria-label][tooltip-position]::after {
  --tooltip-color: var(--neutral1000);
  --arrow-size: 0.5rem;
  --scale: 0;

  z-index: 1;
  transform: translate(var(--translate-x), var(--translate-y))
    scale(var(--scale));
}

/** Top */
[aria-label][tooltip-position="top"]::before {
  position: absolute;
  --translate-x: -50%;
  color: var(--neutral100);
  --translate-y: calc(-100% - var(--arrow-size));
  top: 0px;
  left: 50%;
  transform-origin: bottom center;
  width: 220px;
}

[aria-label][tooltip-position="top"]::after {
  --translate-x: -50%;
  --translate-y: calc(-1 * var(--arrow-size));
  top: 0px;
  left: 50%;
  border-top-color: var(--tooltip-color);
  transform-origin: bottom center;
}

.popularTagContainer {
   display: flex !important;
    @include align-items(center);
    @include justify-content(space-between);
    gap: 4px;

    & > .popularTag {
        @include flexbox();
        @include align-items(center);
        gap: 4px;
        & > .tagToogle {
            height: 16px;
        }
    }
}


.activationToggle {
    @include flexbox();
    @include flex-direction(row);
    @include flex-wrap(wrap);
    @include align-items(flex-end);
    @include justify-content(center);
    .activationCheckbox {
        height: 0;
        width: 0;
        visibility: hidden;
    }
    .activationCheckboxLabel {
        cursor: pointer;
        text-indent: 49px;
        width: 34.75px;
        height: 19.74px;
        background: var(--neutral400);
        border: 2.25px solid var(--neutral400);
        display: block;
        border-radius: 90px;
        position: relative;
        &::after {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 19.75px;
            height: 19.75px;
            background: var(--neutral100);
            border-radius: 90px;
            transition: 0.3s;
        }
    }
}

.activationCheckbox:checked + .activationCheckboxLabel {
    background: var(--primary700);
    border-color: var(--primary700);
}

.activationCheckbox:checked + .activationCheckboxLabel:after {
    left: calc(100% - 19.75px);
    border-color: var(--primary700);
}

.yearlyDiscountContainer {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    gap: 4px;

    & > .yearlyDiscount {
        @include flexbox();
        @include align-items(center);
        gap: 4px;
        & > .tagToogle {
            height: 16px;
        }
    }
}

.advancedOptions > p {
    cursor: pointer;
    color: var(--primary700);
    gap: 4px;
    @include flexbox();
    @include align-items(center);
    & > .toogleOpen {
        transform: rotate(180);
        -webkit-transform: rotate(180deg);
    }
}

.priceInput {
    & > div > div > label {
        font-family: var(--base) !important;
        font-size: 12px !important;
        font-weight: 400 !important;
        line-height: 16px !important;
        text-decoration: none !important;
        color: var(--neutral700);
    }
}

.trialDaysInput {
    display: block;
}

.advancedOptions {
    display: block;
    & > .ebsiDidsContainer {
         display: block;
        margin-top: 20px;
        border: 1px solid var(--neutral400);
        padding: 16px 12px;
        border-radius: 8px;
    }
}

.tagTogleShort {
    &:before {
        width: 100px !important;
    }
}

.saveMobileButton {
    display: block;
    @include min-width($desktopMD) {
        display: none;
    }
}
