@import '../../../../../../../styles/media-mixins';

.filtersDateContainer{
    position: relative;
    width: 100%;
    display: block;
    .filtersDateContainer__content {
        position: relative;
        margin-bottom: 32px;
        @include min-width($tabletSM) {
            position: absolute;
            right: 0;
            top: -67px;
            margin-bottom:0;
        }
        @include min-width($tabletMD) {
            position: relative;
            top: 0;
            margin-bottom: 32px;
        }
        @include min-width($desktopSM) {
            position: absolute;
            right: 0;
            top: -67px;
            margin-bottom: 0;
        }
    }
}
.clientsMetrics__container {
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(space-between);
    @include align-items(center);
    margin-top: 32px;
    gap: 32px;
    .clientsMetrics__metricsBlocks {
        @include flex(0 0 100%);
        @include min-width($desktopXXL) {
            @include flex(0 0 calc(25% - 32px));
        }
    }
    .clientsMetrics__chart {
        @include flex(0 0 100%);
        @include min-width($desktopXXL) {
            @include flex(0 0 75%);
        }
    }
}