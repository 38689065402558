@import '../../../../styles/media-mixins';

.hoverTooltipContainer {
    display: block;
    width: 100%;
}
#clearHoverTooltip {
    z-index: 99999999;
    display: none;
    min-width: 0 !important;
    padding: 20px;
    border: 1px solid #1e1e20;
    background-color: white;
    color: #1e1e20;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.32px;
    line-height: 18px;
    & > div {
        z-index: 999999999 !important;
    }
    @include min-width($desktopMD) {
        display: inline-flex;
    }

    & > span {
        border-bottom: 0.4296875rem solid white;
    }

    & > div > strong {
        margin-bottom: 15px;
        color: #181b5e;
    }
}

#blueHoverTooltip {
    z-index: 99999999;
    display: none;
    min-width: 0 !important;
    padding: 10px;
    border: 1px solid #1e1e20;
    background-color: #1e1e20;
    color: white;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.32px;
    line-height: 18px;
    font-weight: normal;
    width: 180px;
    & > div {
        z-index: 999999999 !important;
    }

    @include min-width($desktopMD) {
        display: inline-flex;
    }

    & > span {
        border-bottom: 0.4296875rem solid #1e1e20;
    }

    & > div > strong {
        margin-bottom: 15px;
        color: #181b5e;
    }
}

#clearHoverTooltipMobile {
    z-index: 99999999;
    left: 10px !important;
    display: block;
    min-width: 0 !important;
    padding: 20px 10px;
    border: 1px solid #545ad7;
    background-color: white;
    color: #1e1e20;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.32px;
    line-height: 18px;

    & > div {
        z-index: 999999999 !important;
    }

    @include min-width($desktopMD) {
        display: none;
        height: 0;
    }

    & > span {
        border-bottom: 0.4296875rem solid white;
    }

    & > div {
        overflow-y: scroll;
        max-width: 250px;
        max-height: 220px;
        padding: 0 10px;
    }

    & > div > strong {
        color: #1e1e20;
    }
}

#blueHoverTooltipMobile {
    z-index: 99999999;
    left: 10px !important;
    display: block;
    min-width: 0 !important;
    padding: 10px;
    border: 1px solid #1e1e20;
    background-color: #1e1e20;
    color: white;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.32px;
    line-height: 18px;
    font-weight: normal;
    width: 200px;
    & > div {
        z-index: 999999999 !important;
    }

    @include min-width($mobileMD) {
        display: none;
        height: 0;
    }

    & > span {
        border-bottom: 0.4296875rem solid #1e1e20;
    }

    & > div {
        overflow-y: scroll;
        max-width: 250px;
        max-height: 220px;
        padding: 0 10px;
    }

    & > div > strong {
        color: #1e1e20;
    }
}

.hoverTooltipDesktop {
    display: none !important;

    @include min-width($desktopMD) {
        display: inline-flex !important;
    }
}

.hoverTooltipMobile {
    display: inline-flex !important;

    @include min-width($mobileMD) {
        display: none !important;
    }
}

.tooltipButton {
    margin: 20px auto 0 !important;
    padding: 15px !important;
}

.linkText {
    color: white !important;

    & > a {
        color: rgb(11, 159, 238) !important;
        &:hover {
            text-decoration: underline;
        }
    }
}

#clearHoverTooltipTablet {
    z-index: 99999999;
    left: 10px !important;
    display: none;
    min-width: 0 !important;
    padding: 20px 10px;
    border: 1px solid #545ad7;
    background-color: white;
    color: #1e1e20;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.32px;
    line-height: 18px;

    & > div {
        z-index: 999999999 !important;
    }
    @include min-width($mobileMD) {
        display: block;
    }
    @include min-width($desktopMD) {
        display: none;
        height: 0;
    }

    & > span {
        border-bottom: 0.4296875rem solid white;
    }

    & > div {
        overflow-y: scroll;
        max-width: 250px;
        max-height: 220px;
        padding: 0 10px;
    }

    & > div > strong {
        color: #181b5e;
    }
}

#blueHoverTooltipTablet {
    z-index: 99999999;
    left: 10px;
    display: none;
    min-width: 0 !important;
    padding: 10px;
    border: 1px solid #1e1e20;
    background-color: #1e1e20;
    color: white;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.32px;
    line-height: 18px;
    font-weight: normal;
    width: 200px;
    & > div {
        z-index: 999999999 !important;
    }

    @include min-width($mobileMD) {
        display: inline-flex;
    }
    @include min-width($desktopMD) {
        display: none;
        height: 0;
    }

    & > span {
        border-bottom: 0.4296875rem solid #1e1e20;
    }

    & > div {
        overflow-y: scroll;
        max-width: 250px;
        max-height: 220px;
        padding: 0 10px;
    }

    & > div > strong {
        color: #181b5e;
    }
}

.hoverTooltipTablet {
    display: none !important;
    @include min-width($mobileMD) {
        display: inline-flex !important;
    }
    @include min-width($desktopMD) {
        display: none !important;
    }
}

.hoverTooltip {
    display: flex;
    align-items: flex-start;

    & > div {
        width: 20px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        & > svg {
            display: none;
            fill: #f97e79;
            background-color: #f97e79;
            border-radius: 200px;
            rotate: unset;
            transform: rotate(183deg);
            & > path:first-child {
                fill: white;
            }

            &:hover {
                outline: 1px solid #f6f6f600 !important;
            }
        }
        &::before {
            content: '!';
            display: inline-block;
            width: 8px;
            height: 13px;

            margin: 8px auto auto;

            background-color: #f97e79;
            color: white;
            border-radius: 20px;
            padding-left: 5px;
            font-size: 10px;
            font-weight: bold;
        }
        outline: none !important;
        &:focus,
        &:hover {
            outline: 1px solid #f6f6f600 !important;
        }
    }
}

.hideIcon {
    height: 40px;
    & > div {
        height: 40px;
        & > div {
            width: 1px;
            cursor: auto !important;
            margin-left: 0 !important;
            &::before {
                display: none;
            }
        }
    }
}
