@import '../../../../../styles/media-mixins';
@import '../../../../../styles/fonts';

.modalDatePicker {
  position: absolute;
  z-index: 9999999;
  background-color: var(--neutral700);
  padding: 0 16px;
  border-radius: 12px;
  top: 43px;
  right: -1px;
  box-shadow: 0 2px 4px var(--boxShadowDark);

  & > div {
    & > div:nth-child(1) {
      display: none;
    }
    & > div:nth-child(2) {
     padding-top: 0;
    }
  }
}