@import '../../../../../styles/fonts';

.featuresFilter {
    margin-bottom: 24px;
    display: block;
    select {
        border: none !important;
        padding: 0 !important;
        width: auto !important;
        font-family: var(--custom) !important;
        line-height: 24px !important;
        font-weight: 700 !important;
        font-size: 20px !important;
        height: auto !important;
        margin-top: 0 !important;
        letter-spacing: 0 !important;
        margin-left: -4px;
    }
    .featuresFilter__dates {
        display: block;
        margin-top: 4px;
    }
}
