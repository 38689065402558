@import '../../../styles/media-mixins';

.headerRightContent {
    position: relative;
}

.header__container {
    gap: 12px;
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(space-between);
    @include align-items(center);
}
.homeMetrics__container {
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(space-between);
    @include align-items(center);
    margin-top: 32px;
    gap: 32px;
    .homeMetrics__homeBlocks {
        @include flex(0 0 100%);
        @include min-width($desktopXXL) {
            @include flex(0 0 calc(25% - 32px));
        }
    }
    .homeMetrics__chart {
        @include flex(0 0 100%);
        @include min-width($desktopXXL) {
            @include flex(0 0 75%);
        }
    }
}