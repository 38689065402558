@import "../../../../styles/media-mixins";

.backgroundLayerActive {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #0000005e;

    @media (min-width: 775px) {
        width: 0;
        height: 0;
    }
}

.backgroundLayerInactive {
    left: 0;
    background-color: transparent;
    transition: all 0.3s linear;
}

.buttonContainer {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 50px;
    height: 16px;
    padding: 16px 15px;

    @media (min-width: 775px) {
        display: none;
    }

    & > div {
        display: block;
        height: 0.2rem;
        border-radius: 10px;
        background-color: var(--neutral1000);
    }
}

.closed {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;

    @media (min-width: 775px) {
        display: none;
    }

    & :nth-child(1) {
        width: 20px;
        background-color: var(--neutral1000);
        transform: rotate(0);
        transition: all 0.3s linear;
    }

    & :nth-child(2) {
        width: 20px;
        opacity: 1;
        background-color: var(--neutral1000);
        transform: translateX(0);
        transition: all 0.3s linear;
    }

    & :nth-child(3) {
        width: 20px;
        background-color: var(--neutral1000);
        transform: rotate(0);
        transition: all 0.3s linear;
    }
}

.open {
    z-index: 9999;
    cursor: pointer;

    @media (min-width: 775px) {
        display: none;
    }

    & :nth-child(1) {
        width: 25px;
        transform: rotate(45deg);
        transform-origin: 1px;
        transition: all 0.3s linear;
    }

    & :nth-child(2) {
        width: 25px;
        opacity: 0;
        transform: translateX(100%);
        transform-origin: 1px;
        transition: all 0.1s linear;
    }

    & :nth-child(3) {
        width: 25px;
        margin-top: 10px;
        transform: rotate(-45deg);
        transform-origin: 1px;
        transition: all 0.3s linear;
    }
}
