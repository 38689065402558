@import "../../../../../styles/colors";
@import "../../../../../styles/fonts";
@import "../../../../../styles/media-mixins";

.prevLicense {
  max-width: calc(297px - 40px);
  display: block;
  padding: 20px;
  border-radius: 12px;
  position: relative;
  box-shadow:  0px 0px 8px 0px var(--boxShadowExtraLigh);
  background-color: var(--neutral100);
  display: flex;
  flex-direction: column;

  &__tag {
    display: block;
    position: absolute;
    width: 100px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border-radius: 3px;
    top: -12px;
    border: 1px solid var(--neutral300);
    border-radius: 10px;
    height: auto;
    left: 75px;
    background-color: var(--neutral100);
  }

  &__header {
    width: 100%;
    gap: 4px;
    @include flexbox();
    @include flex-direction(row-reverse);
    @include flex-wrap(wrap);
    @include justify-content(space-between);

    &__title {
      margin-bottom: 0;
      position: relative;
      width: 100%;
      word-wrap: break-word;
      &:before {
        content: "1";
        position: absolute;
        top: 0;
        left: -32px;
        background-color: var(--primary700);
        color: var(--neutral100);
        font-family: var(--base);
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        text-decoration: none;
        border-radius: 30px;
        width: 6px;
        height: 18px;
        padding: 2px 9px 0;
        @include flexbox();
        @include flex-direction(row);
        @include align-items(center);
        @include justify-content(center);
      }
    }

    & > .withTag {
        width: calc(100% - 114px);
    }

    &__popularTag {
      width: fit-content;
      margin-left: auto;
      position: relative;
      height: 22px;
      &:before {
        content: "3";
        position: absolute;
        top: 0;
        left: -34px;
        background-color: var(--primary700);
        color: var(--neutral100);
        font-family: var(--base);
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        text-decoration: none;
        border-radius: 30px;
        width: 6px;
        height: 18px;
        padding: 2px 9px 0;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
      }
    }
  }

  &__description {
    position: relative;
    width: 100%;
    word-wrap: break-word;
    &:before {
      content: "2";
      position: absolute;
      top: 0;
      left: -32px;
      background-color: var(--primary700);
      color: var(--neutral100);
      font-family: var(--base);
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      text-decoration: none;
      border-radius: 30px;
      width: 6px;
      height: 18px;
      padding: 2px 9px 0;
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
    }
  }

  &__price {
    position: relative;
    margin-top: 12px;
    width: 100%;
    word-wrap: break-word;

    &:before {
      content: "4";
      position: absolute;
      top: 14px;
      left: -32px;
      background-color: var(--primary700);
      color: var(--neutral100);
      font-family: var(--base);
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      text-decoration: none;
      border-radius: 30px;
      width: 6px;
          padding: 1px 9px 0;
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
    }
  }

  &__customPrice {
    margin-top: 24px;
  }

  &__features {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid var(--neutral300);
    width: 100%;
    gap: 16px;
    @include flexbox();
    @include justify-content(space-between);
    @include flex-wrap(wrap);

    & > div {
      @include min-width(646px) {
        min-width: 249px;
      }
    }

    &__list__item {
        margin-top: 16px;
        align-items: center;
        @include flexbox();
        @include flex-direction(row);
        &__content {
          @include flexbox();
          @include flex-direction(row);
          @include flex-wrap(wrap);
          margin-left: 8px;
          & > .featureName {
            width: fit-content;
            margin-bottom: 0;
          }
        }
      }
  }

  &__button {
    width: 100%;
  }
}

.verificationFeatures {
  position: relative;
  &:before {
    content: "5";
    position: absolute;
    top: 0;
    left: -32px;
    background-color: var(--primary700);
    color: var(--neutral100);
    font-family: var(--base);
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    text-decoration: none;
    border-radius: 30px;
    width: 6px;
    height: 18px;
    padding: 2px 9px 0;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
  }
}

.issuanceFeatures {
  position: relative;
  &:before {
    content: "6";
    position: absolute;
    top: 0;
    left: -32px;
    background-color: var(--primary700);
    color: var(--neutral100);
    font-family: var(--base);
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    text-decoration: none;
    border-radius: 30px;
    width: 6px;
    height: 18px;
    padding: 2px 9px 0;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
  }
}

.otherFeatures {
  position: relative;
  &:before {
    content: "7";
    position: absolute;
    top: 0;
    left: -32px;
    background-color: var(--primary700);
    color: var(--neutral100);
    font-family: var(--base);
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    text-decoration: none;
    border-radius: 30px;
    width: 6px;
    height: 18px;
    padding: 2px 9px 0;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
  }
}
