@import "../../../../styles/_colors.scss";

.description {
    color: var(--neutral1000);
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 0.32px;
    line-height: 18px;
    overflow-wrap: break-word;
}
