@import '../../../styles/media-mixins';

.tag {
    width: -moz-fit-content;
    width: fit-content;
    padding: 4px 8px;
    border: none;
    border-radius: 5px;
    color: var(--neutral100);
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;

    & > div {
        & > img {
            margin-left: 10px;
        }
    }

    & > svg {
        margin-right: 4px;
        & > path {
            stroke: var(--neutral100);
        }
    }  
}

.white {
    background-color: var(--neutral100);
    color: var(--neutral1000);

    &.interactive {
        &:hover {
            background-color: var(--neutral200);
        }
    }

    & > svg {
        & > path {
            stroke: var(--neutral1000);
        }
    }
}

.purple {
    background-color: var(--primary700);
    &.interactive {
        &:hover {
            background-color: var(--primary800);
        }
    }
}

.red {
    background-color: var(--alert300);
    &.interactive {
        &:hover {
            background-color: var(--alert400);
        }
    }
}

.green {
    background-color: var(--success300);
    &.interactive {
        &:hover {
            background-color: var(--success400);
        }
    }
}

.yellow {
    background-color: var(--warning300);
    color: var(--neutral1000);

    & > svg {
        & > path {
            stroke: var(--neutral1000);
        }
    }
    &.interactive {
        &:hover {
            background-color: var(--warning400);
        }
    }
}

.blue {
    background-color: var(--secondary600);
    color: var(--neutral100);
    &.interactive {
        &:hover {
            background-color: var(--warning400);
        }
    }
}

.teal {
    background-color: var(--secondary600);
    &.interactive {
        &:hover {
            background-color: var(--secondary800);
        }
    }
}

.pink {
    background-color: var(--tertiary600);
    &.interactive {
        &:hover {
            background-color: var(--tertiary800);
        }
    }
}

.grey {
    background-color: var(--neutral300);
    cursor: auto;
    color: var(--neutral1000);

    &.interactive {
        &:hover {
            background-color: var(--neutral400);
        }
    }

    & > svg {
        & > path {
            stroke: var(--neutral1000);
        }
    }
}
