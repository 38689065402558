@import '../../../../../styles/media-mixins';
@import '../../../../../styles/fonts';

.gaSelectContainer.required {
    div {
        display: block;
    }
    & > div > div {
        & > label {
            font-family: var(--base);
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            text-decoration: none;
            color: var(--neutral700) !important;
            margin-bottom: 0.5rem;
            &::after {
                color: var(--neutral700);
                content: ' *';
            }
        }

        & > div {
            max-height: 7.4rem !important;
            display: block;

            &::-webkit-scrollbar {
                -webkit-appearance: none;
            }

            &::-webkit-scrollbar:vertical {
                width: 11px;
            }

            &::-webkit-scrollbar:horizontal {
                height: 20px;
            }

            &::-webkit-scrollbar-thumb {
                border: 2px solid var(--neutral100); /* should match background, can't be transparent */
                border-radius: 8px;
                background-color: var(--neutral300);
            }
        }
    }

    & > .comboBoxLabel {
        font-family: var(--base);
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-decoration: none;
        color: var(--neutral700);
        margin-bottom: 4px;
        margin-bottom: 0.5rem;
        &::after {
            color: var(--neutral700);
            content: ' *';
        }
    }
}

.gaSelectContainer {
    & > div > div {
        & > label {
            font-family: var(--base);
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            text-decoration: none;
            margin-bottom: 0.5rem;
            color: var(--neutral700)!important;
        }

        & > div {
            max-height: 7.4rem !important;

            & > select {
                margin-top: 0.5rem;
                margin-right: 0 !important;
                letter-spacing: 0.16px;
                outline: 2px solid transparent;
                outline-offset: -2px;
                width: 100%;
                height: 2.5rem;
                padding: 0 1rem;
                border: none;
                border-radius: 8px;
                background-color: transparent;
                border: 1px solid var(--neutral400);
                color: var(--neutral1000);
                font-family: var(--base);
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-decoration: none;
            }
            & > svg {
                display: none !important;
            }
            & > div  {
                & > select {
                    margin-right: 0 !important;
                }
                & > svg {
                    display: none !important;
                }
            }
        
            &::-webkit-scrollbar {
                -webkit-appearance: none;
            }

            &::-webkit-scrollbar:vertical {
                width: 11px;
            }

            &::-webkit-scrollbar:horizontal {
                height: 20px;
            }

            &::-webkit-scrollbar-thumb {
                border: 2px solid white; /* should match background, can't be transparent */
                border-radius: 8px;
                background-color: var(--neutral300);
            }
        }
        
    }

    & > .comboBoxLabel {
       font-family: var(--base);
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-decoration: none;
        color: var(--neutral700);
        margin-bottom: 4px;
    }
}

.gaSelectContainer {
    & > div > div {
        background-color: transparent !important;
        color: var(--neutral1000) !important;
        outline: none !important;
        & > div {
            color: var(--neutral1000) !important;
            & > select:disabled {
                background-color: transparent;
                color: var(--neutral600);
                opacity: 1 !important;
                background-color: var(--neutral200);
                border: 1px solid var(--neutral200);
                outline: none !important;
                border: none;
            }

            & > svg {
                display: none;
            }
        }
    }
}
