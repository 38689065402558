@import '../../../../../../../styles/media-mixins';

.backgroundLayerActive {
    position: fixed;
    z-index: 999999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    cursor: auto;
}

.dropdownActionsContainer {
    position: relative;
    padding-top: 5px;
}

.dropdownActionsButton {
    z-index: 99999;
    overflow: hidden;
    width: 30px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;

    & > svg {
        height: 100% !important;
    }

    &:hover {
        transition: background-color 0.2s ease-in-out;
        -webkit-transition: background-color 0.2s ease-in-out;
        -ms-transition: background-color 0.2s ease-in-out;

        & > svg {
            & > path {
                fill: var(--tertiary500);
                stroke-width: 3px;
            }
        }
    }

    &__icon {
        transition: transform 0.3s ease-in-out;
        -webkit-transition: -webkit-transform 0.3s ease-in-out;
        -ms-transition: -ms-transform 0.3s ease-in-out;
    }

    &__iconActive {
        transform: rotate(180deg);
        transition: transform 0.3s ease-in-out;
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -webkit-transition: -webkit-transform 0.3s ease-in-out;
        -ms-transition: -ms-transform 0.3s ease-in-out;
    }
}

.menuOptionsCard {
    position: absolute;
    z-index: 9999999;
    min-width: 127px;
    right: 28px;
    top: -28px;
    background: transparent;
    padding-bottom: 15px;

    cursor: auto;

    & > ul {
        background-color: var(--neutral100);
        box-shadow: 0 3px 10px var(--boxShadowLigh);
        border-radius: 10px;
        padding: 8px;
    }

    &__item {
        background-color: var(--neutral100);
        padding: 8px;
        border-radius: 8px;
        cursor: pointer;
        @include flexbox();
        @include align-content(center);
        @include align-items(center);
        @include justify-content(flex-start);

        & > a {
            width: 100%;
        }

        &:last-child {
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        &:first-child {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }

        &:hover {
            background-color: #f1f1f1;
            transition: background-color 0.1s ease-in-out;
            -webkit-transition: background-color 0.1s ease-in-out;
            -ms-transition: background-color 0.1s ease-in-out;
        }
    }

    &__item__section {
        background-color: var(--neutral100);
        padding: 8px;
        @include flexbox();
        @include align-content(center);
        @include align-items(center);
        @include justify-content(flex-start);

        &:first-child {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
    }

    &__icon {
        width: 20px;
        margin-right: 10px;
    }

    &__red {
        & > p {
            color: var(--tertiary500) !important;
        }
    }
}

.active {
    display: block;
}

.inActive {
    display: none;
}

.alignLeft {
    width: 85px;
    margin-left: 0;
    margin-right: 50px;
}
