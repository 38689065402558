@import "../../../../styles/media-mixins";

.backgroundLayerActive {
    position: fixed;
    z-index: 999999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #0000005e;
}

.dropdownButton {
    z-index: 9999999;
    overflow: hidden;
    width: 40px;
    height: 40px;
    padding: 0;
    border: none;
    border-radius: 100%;
    background-color: white;
    cursor: pointer;

    &:hover {
        transition: background-color 0.2s ease-in-out;
        -webkit-transition: background-color 0.2s ease-in-out;
        -ms-transition: background-color 0.2s ease-in-out;

        & > svg {
            path {
                fill: var(--primary900);
            }
        }
    }

    &__icon {
        width: 15px;
        transition: transform 0.3s ease-in-out;

        -webkit-transition: -webkit-transform 0.3s ease-in-out;
        -ms-transition: -ms-transform 0.3s ease-in-out;
    }

    &__iconActive {
        transform: rotate(180deg);
        transition: transform 0.3s ease-in-out;

        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -webkit-transition: -webkit-transform 0.3s ease-in-out;
        -ms-transition: -ms-transform 0.3s ease-in-out;
    }
}

.menuOptions {
    position: absolute;
    z-index: 9999999;
    top: 71px;
    right: 15px;
    width: 150px;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 3px 10px var(--boxShadowLigh);
   

    &__item {
        display: flex;
        align-content: center;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        padding: 20px;
        cursor: pointer;

        &:last-child {
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
        }

        &:first-child {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
        }

        &:not(:last-child) {
            border-bottom: 1px solid var(--neutral300);
        }

        &:hover {
            background-color: #f1f1f1;
            transition: background-color 0.1s ease-in-out;

            -webkit-transition: background-color 0.1s ease-in-out;
            -ms-transition: background-color 0.1s ease-in-out;
        }
    }

    &__icon {
        width: 20px;
        margin-right: 10px;
    }
}

.active {
    display: block;
}

.inActive {
    display: none;
}
