@import "../../../../../../../../styles/colors.scss";
@import "../../../../../../../../styles/media-mixins.scss";

.metricsSubBlocks {
    margin-top: 32px;
    display: block;
    border-radius: 12px;
    background-color: var(--neutral100);
    padding: 26.5px;
    .metricsSubBlocks__container {
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify-content(space-between);
        gap: 20px;
        @include min-width($desktopSM) {
            @include justify-content(flex-start);
        }
        @include min-width($desktopXL) {
            @include justify-content(space-between);
        }
        > div:last-child {
            .metricsSubBlocks__each_container .metricsSubBlocks__each_title {
                max-width: 28px;
            }
        }
        .metricsSubBlocks__each {
            @include flex(0 0 calc((100%/2) - (40px + 4px)));
            border-radius: 12px;
            padding: 16px;
            border: 1px solid var(--neutral300);
            @include min-width($tabletMD) {
                @include flex(0 0 calc((100%/3) - (40px + 9px)));
            }
            @include min-width($desktopMD) {
                @include flex(0 0 calc((100%/4) - (40px + 9px)));
            }
            @include min-width($desktopXL) {
                @include flex(0 0 calc((100%/6) - (40px + 12px)));
            }
            .metricsSubBlocks__each_container {
                @include flexbox();
                @include flex-direction(column);
                @include flex-wrap(wrap);
                @include justify-content(center);
                @include align-items(center);
                gap: 8px;
                .metricsSubBlocks__each_number {
                    color: var(--neutral1000);
                }
                .metricsSubBlocks__each_title {
                    color: var(--neutral1000);
                    text-align: center;
                    max-width: 68px;
                }
            }
        }
    }
}
// Tooltip
[aria-hidden="true"][aria-label][tooltip-position]::before,
[aria-hidden="true"][aria-label][tooltip-position]::after {
    display: none;
}

[aria-label][tooltip-position]::before {
    font-size: 12px;
}