@import "../../../../styles/colors.scss";
@import "../../../../styles/media-mixins.scss";

.greyButton {
    border-radius: 8px;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    cursor: pointer;

    & > svg {
        margin-left: 8px;
    }
    & > span {color:  var(--neutral100);}
}

// SIZES
.large {
    padding: 12px 24px;
    height: 48px;
}

.medium {
    padding: 12px 24px;
    height: 48px;
}

.small {
    padding: 8px 16px;
    height: 32px;
}

// STYLE
.filled {
    border: 1px solid var(--neutral500);
    background-color: var(--neutral500);
    color: var(--neutral100);
    & > span {
        color: var(--neutral100);
    }
    &:hover {
        border: 1px solid var(--neutral500);
        background-color: var(--neutral500);
        color: var(--neutral100);
        box-shadow: 0px 0px 0px 1px var(--neutral300);
        & > span {
            color: var(--neutral100);
        }
    }
}

.outlined {
    border: 1px solid var(--neutral500);
    background-color: var(--neutral100);
    color: var(--neutral500);
    & > span {
        color: var(--neutral1000);
    }
    &:hover {
        border: 1px solid var(--neutral500);
        background-color: var(--neutral100);
        color: var(--neutral500);
        box-shadow: 0px 0px 0px 1px var(--neutral300);
        & > span {
            color: var(--neutral1000);
        }
    }
}

.filledDisabled, .outlinedDisabled {
    cursor: auto !important;
}
