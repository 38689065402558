@import "../../../styles/media-mixins";

.paginator {
    border-top: none;
    border-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: white !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.28572;
    letter-spacing: 0.16px;
    display: flex;
    width: calc(100%);
    min-height: 2.5rem;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e0e0e0;
    flex-wrap: wrap;
    overflow: hidden;

     @media (min-width: 672px) and (max-width: 975px) {
        & > div:first-child {
            & > div {
                display: none;
            }

            & > label {
                display: none;
            }
        }
   }

    & > div {
        border-top: none;
        min-height: 41px;
        display: flex;
        height: 100%;
        align-items: center;
        gap: 12px;
        & > div > div > div > div >  svg {
            display: none;
        }        

        button {
            height: 41px;
            width: 41px;
            border: 1px solid #e0e0e0;
        }

        @media (max-width: 634px) {
            border-top: 1px solid  #e0e0e0;
            width: 100%;
            justify-content: space-between;
        }
    }

    & > div:first-child {
        border-top: none;
        padding: 0 12px ;
        border-right: 1px solid #e0e0e0;
    
        select {
            height: 41px;
            min-width: 56px;
            padding: 5px 8px;
            background-color: #80808021;
            border: none;
        }
    }

    & > div:last-of-type {
        & > div:first-of-type > div {
          
            gap: 12px;
            display: flex;
            & > label {
                display: none;
            }

            select {
                height: 41px;
                min-width: 56px;
                padding: 5px 8px;
                background-color: #80808021;
                border: none;
            }
        }

        & > div:last-of-type > span:first-of-type { 
            button {
                border-right: none;
                border-bottom: none;
                border-top: none;
                cursor: pointer;
            }
            button:disabled {
                cursor: not-allowed;
            }
        }

        & > div:last-of-type > span:last-of-type { 
            button {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-top: none;
                border-right: none;
                border-bottom: none;
                cursor: pointer;
            }
            button:disabled {
                 cursor: not-allowed;
            }
        }
    }
}

