@import '../../../../../../styles/media-mixins';

.panels {
    gap: 32px;
    @include flexbox();
    @include flex-wrap(wrap);

    @include min-width($desktopXL) {
        min-width: 350px;
        flex: 0 1 432px;
        flex-wrap: nowrap;
    }
   
}

.formLightPanel:nth-of-type(1) {
    width: 100%;
    @include min-width($desktopXL) {
        min-width: 350px;
        flex: 0 1 432px;
    }
}

.formLightPanel:nth-child(2) {
    width: 100%;
    @include min-width($desktopXL) {
        min-width: 350px;
        flex: 0 1 700px;
    }
}

.formLightPanel {
    gap: 24px;
    padding: 24px;
    width: 100%;
    border-radius: 20px;
    @include flexbox();
    @include flex-wrap(wrap);
    @include flex-direction(column);
    
    &__header {
        width: 100%;
        display: flex;
        gap: 10px;
        @include justify-content(space-between);
        @include align-items(center);
        @include flex-wrap(wrap-reverse);
        @include flex-direction(row);

        & > button {
            height: 32px;
            padding: 5px 16px !important;
            font-family: var(--base);
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            text-decoration: none;
            min-width: 58px;
            margin-left: auto;
        }
        & > div {
            margin-left: auto;
        }

    }

    &__content {
        width: calc(100% - 48px);
        border: 1px solid var(--neutral400);
        padding: 20px;
        border-radius: 12px;
        gap: 8px;
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify-content(space-between);
        @include flex-direction(column);
    }


    .form {
        width: 100%;
        cursor: pointer;
        @include flexbox();
        @include align-items(flex-end);
        @include justify-content(center);
        @include flex-direction(column-reverse);

        @include min-width($desktopSM) {
            @include flex-direction(row);
            @include flex-wrap(wrap-reverse);
            @include justify-content(space-between);
        }

        &__textInputs {
            gap: 16px;
            width: 100%;
            @include flexbox();
            @include flex-direction(column);

            @include min-width($desktopSM) {
                min-width: 200px;
            }
            &__doubleColumn {
                gap: 12px;
                @include flexbox();
                @include justify-content(space-between);
                @include flex-wrap(wrap);
             
                & > div {
                    width: 48%;
                }

                & > div:nth-child(3) {
                    margin-top: 15px;
                }

                & > div:nth-child(4) {
                    margin-top: 15px;
                }
            }
        }

        &__imageInput {
            margin-right: auto;
            margin-bottom: 20px;
            border-radius: 10px;
            position: relative;
            width: 185px;
            height: 185px;
            background-color: #dadbec;
            @include flexbox();
            @include justify-content(center);
            @include align-items(center);

            &:hover {
                box-shadow: 0 3px 10px var(--boxShadowLigh);
            }

            @include min-width($desktopSM) {
                margin-bottom: none;
                margin-right: 0;
            }

            & > img {
                border-radius: 10px;
                width: 100%;
                height: 100%;
            }

            & > input {
                display: none;
                border-radius: 15px;
                border: 1px #000 solid;
            }

            &__empty {
                width: 100px;
                text-align: center;
                @include flexbox();
                @include justify-content(center);
                @include align-items(center);
                @include flex-direction(column);

                & > img {
                    margin-bottom: 10px;
                }
            }
        }

        &__imageInputFilled {
            &::after {
                content: 'Edit';
                display: none;
                position: absolute;
                font-weight: bold;
                z-index: 9999;
                width: 54px;
                height: 36px;
                top: 0;
                right: 0;
                color: var(--neutral100);
                font-size: 10px;
                line-height: 36px;
                text-align: center;
                background: rgba(0, 0, 0, 0.72);
                border-radius: 3px;
            }

            &:hover {
                &::after {
                    display: block;
                }
            }
        }
    }
}

.warningPanelContainer {
    margin-left: 0px !important; 
    & > div > div > button {
        width: 190px !important;
    }
}

.closeFormIcon {
    cursor: pointer;
    &:hover {
        color: var(--alert300);
    }
}

.formButtons {
    gap: 12px;
    width: 100%;
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(flex-end);

    & > button {
        height: 32px;
        padding: 5px 16px !important;
        font-family: var(--base);
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-decoration: none;
        min-width: 58px;
        width: 100%;

        @include min-width($mobileXL) {
            width: auto;
        }
    }
}
